import React from 'react';
import Layout from '../components/layout/Layout';
import { Section } from '../components/Section';
import { Helmet } from 'react-helmet';
import USCIS from '../images/clients/USCIS.svg';
import SEC from '../images/clients/SEC.svg';
import GSA from '../images/clients/GSA.svg';
import ICE from '../images/clients/ICE.svg';
import IRS from '../images/clients/IRS.svg';
import NAVY from '../images/clients/NAVY.svg';
import PBGC from '../images/clients/PBGC.svg';
import TSA from '../images/clients/TSA.svg';

const OurClients = () => (
  <Layout>
    <Helmet>
      <meta charSet='utf-8' />
      <title>LightFeather - Capabilities Statement</title>
    </Helmet>
    <Section>
      <div className='bg-white py-24 sm:py-32'>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <h2 className='text-center text-4xl font-semibold leading-8 text-gray-900 pb-8'>
            Trusted by the country's largest government agencies
          </h2>
          <div className='mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-24 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-24 lg:max-w-none lg:grid-cols-4'>
            <img
              className='col-span-2 max-h-12 items-center w-48 object-contain lg:col-span-1'
              src={SEC}
              alt='SEC'
              width={158}
              height={48}
            />
            <img
              className='col-span-2 max-h-12 w-full object-contain lg:col-span-1'
              src={NAVY}
              alt='NAVY'
              width={158}
              height={48}
            />
            <img
              className='col-span-2 max-h-12 w-full object-contain lg:col-span-1'
              src={IRS}
              alt='IRS'
              width={158}
              height={48}
            />
            <img
              className='col-span-2 max-h-12 w-full object-contain lg:col-span-1'
              src={GSA}
              alt='GSA'
              width={158}
              height={48}
            />
            <img
              className='col-span-2 max-h-12 w-full object-contain lg:col-span-1'
              src={USCIS}
              alt='USCIS'
              width={158}
              height={48}
            />
            <img
              className='col-span-2 max-h-12 w-full object-contain lg:col-span-1'
              src={ICE}
              alt='ICE'
              width={158}
              height={48}
            />
            <img
              className='col-span-2 max-h-12 w-full object-contain lg:col-span-1'
              src={TSA}
              alt='TSA'
              width={158}
              height={48}
            />
            <img
              className='col-span-2 max-h-12 w-full object-contain lg:col-span-1'
              src={PBGC}
              alt='PBGC'
              width={158}
              height={48}
            />
          </div>
        </div>
      </div>
    </Section>
  </Layout>
);

export default OurClients;
